<template>
	<div class="open-account-type">

		<h1 class="h1_title">{{translate('fill_in_bank_card')}}</h1>

		<div class="item" @click="toBank(1)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_1.png'" alt=""></div>
			<div class="info">{{translate('domestic_bank_account')}}</div>
		</div>

		<div class="item" @click="toBank(2)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_2.png'" alt=""></div>
			<div class="info">{{translate('overseas_bank_account')}}</div>
		</div>

		<div class="item" @click="toBank(3)">
			<div class="image"><img :src="resourceURL + 'images/bank/icon_bank_3.png'" alt=""></div>
			<div class="info">USDT</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'account_bank',
		data() {
			return {

				token: "",
				
				showBank: false,

				resourceURL: this.$axios.defaults.resourceURL,
				
				bank_front_url: this.$axios.defaults.resourceURL + "images/icon_bank_add.png",
				
				disabledSubmit: true,
				
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				this.init()
			}
		},
		methods: {
			init(){
				
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						}
												
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			},
			
			toBank(bank_type){

				if(bank_type != 1 && bank_type != 2 && bank_type != 3){
					this.$toast.fail(this.translate('please_select_a_bank_card_type'))
					return false
				}

				if(bank_type == 1){
					this.$router.push('/account/bank?token=' + this.token)
				}
				if (bank_type == 2) {
					this.$router.push('/account/hkbank?token=' + this.token)

				}
				if (bank_type == 3) {
					this.$router.push('/account/usdt?token=' + this.token)
				}
			},
		},
	}
</script>